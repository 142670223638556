
import actions from '@/store/modules/flags/actions'
import getters from '@/store/modules/flags/getters';
import mutations from '@/store/modules/flags/mutations'

const state ={
  flags:[]
}

export {
  state,
  getters,
  actions,
  mutations,
}
