<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  size: {
    type: String,
    default: "md",
  },
  src: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "GR",
  },
  cursor: {
    type: String,
    default: "default",
  },
});

const loaded = ref(false);

const initials = computed(() => {
  // função para remover caracteres especiais
  function cleanName(name) {
    return name.replace(/[^a-zA-Z]/g, "");
  }

  const names = props.placeholder
    .trim()
    .split(" ")
    .map(cleanName)
    .filter((name) => name.length > 0);

  // pega a primeira letra do nome e do sobrenome
  if (names.length >= 2) {
    return (names[0][0] + names[names.length - 1][0]).toUpperCase();
  }

  // se tiver apenas um nome, pega as duas primeiras letras desse nome
  const singleName = cleanName(props.placeholder);
  return singleName.slice(0, 2).toUpperCase();
});

function imageLoad() {
  loaded.value = true;
}
</script>

<template>
  <div class="avatar">
    <div
      v-if="!loaded || !src"
      class="initials photo"
      :class="`avatar-${size}`"
      :style="{
        cursor: cursor,
        position: src ? 'absolute' : 'relative',
      }"
    >
      {{ initials ? initials : "GR" }}
    </div>

    <img
      v-if="src"
      :src="src"
      :alt="placeholder"
      class="photo no-photo"
      :class="`avatar-${size}`"
      :style="{ cursor: cursor }"
      loading="lazy"
      @load="imageLoad"
    />
  </div>
</template>

<style scoped lang="scss">
.avatar {
  position: relative;

  .no-photo {
    border-color: transparent !important;
  }

  .photo {
    border-radius: var(--radius-full);
    border: 1px solid var(--primary-100);

    background-color: var(--primary-50);
    color: var(--primary-500);

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: var(--weight-semibold);
    text-transform: capitalize;
    object-fit: cover;
  }

  img {
    animation: show 0.3s both;
  }

  @keyframes show {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  .initials {
    user-select: none;
    z-index: 999;
  }
}

.avatar-sm {
  width: var(--w-8);
  height: var(--h-8);

  font-size: var(--font-xs);
}

.avatar-xsm {
  width: var(--w-10);
  height: var(--h-10);

  font-size: var(--font-xs);
}

.avatar-md {
  width: var(--w-12);
  height: var(--h-12);

  font-size: var(--font-base);
}

.avatar-lg {
  width: var(--w-16);
  height: var(--h-16);

  font-size: var(--font-xl);
}

.avatar-xl {
  width: var(--w-24);
  height: var(--h-24);

  font-size: var(--font-3xl);
}
</style>
